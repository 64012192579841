.add_team {
  &__title {
    margin: 0 !important;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 7px;
  }

  &__form {
    min-width: 500px;

    &_item {
      width: 100%;

      &:first-of-type {
        margin-top: 0;
      }

      &Checkbox {
        margin-top: 0 !important;
      }
    }

    &_row {
      display: flex;
      gap: 15px;
      margin-top: 12px;

      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  &__input {
    &_wrapper {
      width: 100% !important;
    }

    &_phone {
      margin-bottom: 0 !important;
    }
  }

  &__btn {
    margin-top: 12px !important;

    &_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  &__phone {
    &_wrapper {
      display: flex;
      gap: 8px;
      margin-top: 8px;
      flex-wrap: wrap;
      max-width: fit-content;
    }

    &_item {
      position: relative;
      padding: 5px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      background-color: #e5e5e5;
      border-radius: 5px;
      cursor: pointer;
    }

    &Btn {
      min-width: auto !important;
    }

    .MuiButton-startIcon {
      margin: 0 !important;
    }

    &_actions {
      display: flex;
      gap: 10px;
      padding: 5px;
    }
  }

  &__label {
    color: #4d4d4d;
    font-size: 17px;
    margin-top: 19px;
    display: block;
  }
}