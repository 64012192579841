.popup {
  margin-top: 2px !important;

  &__content {
    min-width: 100px;
    background-color: #fff !important;
    padding: 6px 0 !important;
  }

  &__btn {
    background: #1B6AAA !important;
    font-size: 12px;
    height: 27px;
    width: max-content;
    border-radius: 4px !important;
    flex-shrink: 0;

    &:hover {
      background: #1B6AAA !important;
    }

    &:disabled {
      background: #ccc !important;
    }
  }

  &__label {
    color: #fff;
    display: flex;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      padding: 3px 10px;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: all .3s;
      font-size: 12px;

      &:hover {
        background: #f5f5f5;
      }
    }
  }
}