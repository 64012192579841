.filter {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 15px;

  &__item {
    min-width: 200px !important;

    &_sum {
      min-width: unset !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 15px;
      width: 220px;

      span {
        font-size: 30px;
        position: relative;

        &:before {
          content: '-';
          position: absolute;
          top: -15px;
          left: -5px;
        }
      }
    }
  }
}