
.documents {
  &__wrapper {
    min-width: 700px;
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 15px;

    &_item {
      display: grid;
      grid-template-columns: 12fr 220px 110px;
      align-items: flex-end;
      width: 100%;
      gap: 15px;

      &Actions {
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: flex-end;
      }
      &Link {
        display: flex;
        align-items: center;
        max-width: 250px;
        min-width: 250px;
        line-height: 16px;
      }
      &Download {
        width: 17px !important;
        margin-right: 5px;
      }
    }

    &_add {
      text-align: center;
      cursor: pointer;
      color: #184f88;
      font-size: 16px;
      margin: 0;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all .4s;

      &:hover  {
        color: #62a0e1;
      }

      &Wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 25px;
      }
    }
  }
}