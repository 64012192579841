.requests {
  min-width: 330px;

  &__form {
    margin-top: 15px;

    &_item {
      margin-top: 20px;
    }
  }

  &__btn {
    margin-top: 20px !important;

    &_wrapper{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}