.amount {
  padding: 15px;
  max-width: 800px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  &__row {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%
  }
}