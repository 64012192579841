.teams {
  padding: 15px;

  &__component {
    margin-top: 15px;
  }

  &__content {
    margin-top: 15px;
  }

  &__team {
    cursor: pointer;

    &:hover {
      color: #0c4b86;
    }
  }
}

