.add_team {
  &__title {
    margin: 0 !important;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 7px;
  }

  &__form {
    min-width: 500px;

    &_item {
      width: 100%;
      margin-top: 7px;

      &Margin {
        margin-top: 15px;
      }

      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  &__input {
    &_wrapper {
      width: 100% !important;
    }
  }

  &__btn {
    &_wrapper {
      margin-top: 12px !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  &__label {
    color: #4d4d4d;
    font-size: 17px;
    margin-top: 19px;
    display: block;
  }
}