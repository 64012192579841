.debts {

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    &_btns {
      display: flex;
      align-items: flex-start;
      gap: 5px;
      &Mobile {
        display: none;
      }

      @media (max-width: 576px) {
        display: none;
        &Mobile {
          display: flex;
          margin-bottom: 10px;
        }
      }
    }
    @media (max-width: 991px) {
      flex-wrap: wrap;
      row-gap: 12px;
    }
  }
  &__date {
    margin-top: 25px;
  }
  &__all {
    padding: 15px;
  }
  &__content {
    margin-top: 15px;
  }
  &__icon {
    cursor: pointer;
    display: flex;

    &_disabled {
      fill: #ccc;
      color: #ccc;
      cursor: default;

      svg {
        fill: #ccc;
        color: #ccc;
        cursor: default;
      }
    }
  }
  &__amounts {
    display: flex;
    gap: 15px;
    font-size: 16px;
    &_item {
      font-weight: 700;
      margin: 0;

      span {
        font-weight: 400;
      }
    }

    @media (max-width: 768px) {
      flex-wrap: wrap;
      row-gap: 5px;
    }
  }
}