.accounting {

  &__cost {
    &_modal {
      min-width: 370px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 7px;

    &_debt {
      margin-top: 10px;
    }
  }

  &__debtName {
    margin: 0;
  }
  &__remaining {
    margin: 0;
    font-weight: 400;

    &_wrapper {
      margin-top: 12px;
    }

    span {
      font-weight: 700;
    }
  }

  &__back {
    display: flex;
    gap: 3px;
    color: #525252;
    align-items: center;
    cursor: pointer;

    svg {
      font-size: 17px;
    }
  }

  &__title {
    margin: 0 !important;
  }

  &__form {
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &_items {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 10px;
      max-height: 500px;
      overflow-y: auto;
    }
    &_item {
      margin-top: 10px;
      width: 100%;

      &:first-child {
        margin-top: 0;
      }

      &Type {
        margin-top: 27px !important;
      }
      &Debt {
        width: 100%;
        border: 1px solid #ccc;
        padding: 7px;
        border-radius: 3px;
      }

      &Checkbox {
        margin-top: 0 !important;
      }

      &Dif {
        margin-top: 25px !important;
      }
    }
  }

  &__input {
    &_wrapper {
      width: 100% !important;
    }

    &_phone {
      margin-bottom: 0 !important;
    }
  }

  &__btn {
    margin-top: 20px !important;

    &_wrapper {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }

  &__flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  &__phone {
    &_wrapper {
      display: flex;
      gap: 8px;
      margin-top: 8px;
      flex-wrap: wrap;
      max-width: fit-content;
    }

    &_item {
      position: relative;
      padding: 5px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      background-color: #e5e5e5;
      border-radius: 5px;
      cursor: pointer;
    }

    &Btn {
      min-width: auto !important;
    }

    .MuiButton-startIcon {
      margin: 0 !important;
    }

    &_actions {
      display: flex;
      gap: 10px;
      padding: 5px;
    }
  }

  &__master {
    display: flex;
    align-items: center;
    span {
      display: flex;
      align-items: center;
      cursor: help;
      margin-top: 5px;
      font-size: 13px;
      color: #919191;
      transition: all .3s;

      &:hover {
        color: #1b2552;

        svg {
          fill: #1b2552;
        }
      }

      &:first-child svg {
        margin-left: 0;
      }
    }

    &_icon {
      transition: all .4s;
      width: 17px !important;
      height: 17px !important;
      fill: #919191 !important;
      margin-right: 3px;
      margin-left: 5px;
    }
  }

  &__warning {
    color: #d31a1a;
    text-align: center;
    font-weight: 500;
    max-width: 400px;
    font-size: 16px;
  }
}