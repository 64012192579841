.costs {

  &__status {
    position: relative;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 3px;
    flex-wrap: nowrap;
    cursor: pointer;

    &_icon {
      fill: #c54040 !important;
      margin-bottom: 2px;
      font-size: 18px !important;
    }
  }

  &__comment {
    font-size: 13px;
  }
}