.worksets {
  &__content {
    margin-top: 20px;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;

    &_amount {
      text-align: right;
      font-size: 14px;
      font-weight: 700;
      color: #444444;

      div {
        line-height: 23px;
      }

      span {
        font-size: 17px;
        color: #0ca215;
      }

      &All {
        color: #148314 !important;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 7px;
    flex-wrap: wrap;

    &_item {
      width: 220px;
    }

    &_item {
      &Span {
        background-color: #005bcd;
        padding: 0 3px;
        border-radius: 0;
        color: #fff;
      }
    }

    &_btn {
      font-size: 12px !important;
      padding: 5px !important;
      width: auto !important;
      height: auto !important;
      line-height: 12px !important;

      &Margin {
        margin-left: 5px !important;
      }

      &Update {
        background-color: #0c730e !important;
      }

      &Complete {
        background-color: #0ca215 !important;
      }
    }

    span {
      font-size: 12px;
    }

    &_succeed {
      color: #18a216;
      display: flex;
      align-items: center;
      gap: 3px;

      svg {
        font-size: 17px;
      }
    }

    &_clickable {
      text-decoration: underline;
      color: #0e5c8c;
    }
  }

  &__data {
    display: flex;
    align-items: center;
    gap: 8px;

    &_item {
      padding: 3px;
      border-radius: 4px;
      font-size: 13px;
      line-height: 12px;
    }
  }

  &__sum {
    white-space: nowrap;
    padding: 3px;
    background: khaki;
    color: darkgreen;
    font-size: 13px;
  }
}
