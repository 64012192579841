
.wrapper {
  padding: 15px;
}

.content {
  margin-top: 15px;
}

.filters {
  display: flex;
  align-items: flex-end;
  gap: 15px;
  flex-wrap: wrap;

  &__item {
    width: 200px;
  }
}