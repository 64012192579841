.amount {
  padding: 15px;
  max-width: 800px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }
}