.add_team {
  &__title {
    margin: 0 !important;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 7px;
  }

  &__form {
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &_item {
      margin-top: 15px;
      width: 100%;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  &__input {
    &_wrapper {
      width: 100% !important;
    }
  }

  &__btn {
    margin-top: 12px !important;
  }
}