.executive {
  &__wrapper {
    padding: 16px;
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
    width: 100%;
  }
  &__filters {
    margin-bottom: 15px;
    &_item {
      width: 250px;
    }
  }

  &__item {
    width: 100%;
    display: grid;
    grid-template-columns: 5fr 7fr 30px;
    align-items: center;
    gap: 10px;
    padding: 5px 10px;
    background-color: #fff;
    border: 1px solid #ddd;

    &_noData {
      padding: 12px 15px;
      color: #666;
      background: #f5f5f5;
      font-weight: 300;
    }

    &_title {
      span {
        font-weight: bold;
      }
    }

    &_help {
      cursor: pointer;
      color: #1a5fa1;
    }

    &_right {
      display: flex;
      align-content: center;
      justify-content: flex-end;
      gap: 10px;
    }

    @media (max-width: 576px) {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
    }
  }

  &__colors {
    display: flex;
    gap: 2px;

    &_item {
      width: 75px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 2px;

      &Active {
        border: 2px solid #5e5e5e
      }

      span {
        font-size: 12px;
        color: #fff;
        background: #3b7dcf;
        padding: 0 7px;
        height: 22px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      @media (max-width: 576px) {
        width: 60px;
        height: 35px;
      }
    }
  }
}