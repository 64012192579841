.placeholder {
  &__text {
    font-size: 23px;
    color: #abc;
    width: 100%;
    text-align: center;
    margin: -95px 0 0;
    text-transform: uppercase;
    font-weight: 500;
  }
}

@media (max-width: 576px) {
  .placeholder {
    &__img {
      width: 270px;
    }
    &__text {
      margin: -50px 0 0;
    }
  }
}