.image {
  width: 100% !important;
  height: auto !important;
  position: unset !important;

  &__custom {
    max-width: 100% !important;
  }

  &__wrapper {
    display: flex !important;
    justify-content: center;
    align-content: center;
    max-height: 100%;

    &All {
      height: 100%;

      &Width {
        width: 100% !important;
        span {
          width: 100% !important;
        }
      }
    }

    & > span {
      position: unset !important;
    }

    span {
      height: 100% !important;
    }
  }
}