.timetable {
  &__cell {
    width: 100%;
    z-index: 1;
    position: relative;

    &_disabled {
      & > div {
        background-color: #f7faff !important;
        color: #505156 !important;
      }
    }

    & > div {
      background-color: #f7faff !important;
      color: #ffffff;
      padding: 5px;
      width: 100%;
      height: 100%;
      font-size: 13px;
      transition: all 0.4s;
      cursor: pointer;

      & > div {
        border: none !important;
      }

      & > p {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        height: 100%;
        width: 100%;
        font-size: 12px;
      }
    }

    &_event {
      padding: 5px !important;
      z-index: 2;
      background-color: #fff !important;
      border: none !important;
      position: relative;

      &Content {
        background-color: #e7e7e7;
        border: 1px solid #ccc;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        color: #505156;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
      }
    }
  }
}
