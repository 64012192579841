.team_detail {
  padding: 20px;

  &__symbol {
    font-size: 25px;
    color: #ffc40d;
    fill: #ffc40d;
  }

  &__divider {
    margin-top: 25px !important;

    &_small {
      margin: 6px 0 !important;
    }
  }

  &__amount {
    width: 100%;
    justify-content: flex-end;
    padding: 5px;
    border-radius: 3px;
    display: flex;
    font-size: 16px;
    white-space: nowrap;
    line-height: 12px;

    &_wrapper {
      margin: 10px 0 10px 0;
    }

    span {
      font-weight: bold;
      margin-left: 5px;
      font-size: 18px;
    }
  }

  &__header {
    display: flex;
    align-items: center;

    h2 {
      margin-top: 0;
    }
    @media (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start;
      h2 {
        font-size: 18px;
        margin: 0;
      }
    }
  }

  &__content {
    margin-top: 15px;
  }

  &__masters {
    &_filters {
      display: flex;
      align-items: center;
      gap: 15px;
      margin: 12px 0;
      flex-wrap: wrap;

      &Item {
        min-width: 200px;
      }

      &Search {
        max-width: 270px !important;
      }
    }
  }

  &__alert {
    margin-left: 20px !important;

    @media (max-width: 576px) {
      margin-left: 0 !important;
      width: 100%;
    }
  }

  &__row {
    &:hover {
      background-color: #e3e3e3 !important;
    }
  }

  &__table {
    margin-top: 15px;
  }

  &__phone {
    &_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 7px;
    }

    &_item {
      position: relative;
      white-space: nowrap;
      margin: 0 10px;
    }

    &_main {
      position: absolute;
      top: -3px;
      right: -10px;
    }
  }

  &__flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    margin: 10px 0 5px 0;

    & > h2 {
      line-height: 20px;
      margin: 0;
    }

    @media (max-width: 576px) {
      & > h2 {
        font-size: 18px;
      }
    }
  }

  &__master {
    font-size: 16px;
    line-height: 24px;
    margin: 0 !important;
    text-align: right;

    span {
      font-size: 18px;
      font-weight: 700;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
  }
}

.icon {
  position: relative;
  width: 64px;
  margin: 0 auto;

  &__credit {
    color: #3066b9;
  }

  &__check {
    position: absolute;
    font-size: 15px !important;
    color: #51af0c;
    right: 16px;
    bottom: 4px;
    background: #fff;
    border-radius: 50%;
  }
}