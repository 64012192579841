@import "../../../styles/variables";
.wrapper {
  scrollbar-width: thin;
  scrollbar-color: $blue-color transparent;
  &::-webkit-scrollbar {
    width: 4px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $blue-color;
    border-radius: 4px;
  }
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #000;
  }
  &_type_ {
    &thin {
      &::-webkit-scrollbar {
        width: 2px;
      }
    }
  }
}