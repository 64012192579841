.accounting {
  padding: 15px;

  &__page {
    padding: 0 0 50px 0 !important;
  }

  h1, h2 {
    margin: 0;
  }

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 15px;

    &_title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &Remont {
        text-decoration: underline;
        font-weight: 500;
      }

      h1, h2 {
        margin: 0;
      }

      &Value {
        margin-left: 5px;
      }
    }

    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
  }

  &__balance {
    display: flex;
    align-items: center;

    &_icon {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-left: 5px;

      svg {
        fill: #1c6c1b;
        color: #9f9f9f;
        width: 16px !important;
        height: 16px !important;
      }

      &Reload {
        color: #949494 !important;
        font-size: 17px !important;
      }

      &Loading {
        svg {
          animation: icon_loading;
          animation-iteration-count: infinite;
          animation-duration: .3s;
        }
      }
    }

    span {
      color: #0c4b86;
      margin-left: 5px;
    }

    &_contractor {
      color: #8b8b8b;
      font-size: 13px;
    }
  }

  &__amount {
    position: fixed;
    bottom: 0;
    display: flex;
    align-content: center;
    font-size: 20px;
    font-weight: 700;
    padding: 15px 36px;
    background: #fff;
    width: 100%;
    margin-left: -20px;
    border-top: 1px solid #ccc;

    &__alternative {
      padding: 15px 15px 50px;
    }

    h3 {
      margin: 0;
      font-size: 17px;
    }

    span {
      margin-left: 10px;
      font-size: 25px;
    }

    &_zero {
      color: #505050;
    }

    &_cost {
      color: #e73737;
    }

    &_income {
      color: #20a403;
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-top: 15px;
  }
}

@keyframes icon_loading {
  0% {
    transform: rotate(0deg)
  }
  50% {
    transform: rotate(180deg)
  }
  100% {
    transform: rotate(360deg)
  }
}